<template>
  <template v-if="isFirstLoad">
    <SubstituteBenchRow is-header />
    <SubstituteBenchRow v-for="i in 10" :key="i" is-skeleton />
  </template>

  <template v-else-if="contracts.length">
    <SubstituteBenchRow is-header />
    <VirtualTable :items="contracts" :item-height="58" :get-key="(item) => item.id">
      <template #item="{ data: contract }">
        <SubstituteBenchRow
          :contract="contract"
          @on-save="$emit('onSave', contract)"
          @click="contractOpened = contract"
          @on-change="$emit('onSave', contract)"
        />
      </template>
    </VirtualTable>

    <LazySubstituteBenchShowSheet
      v-if="contractOpened"
      :contract="contractOpened"
      @on-close="contractOpened = undefined"
      @on-change="$emit('onSave', contractOpened)"
    />
  </template>

  <EmptyState v-else>
    <EmptyStateTitle>Aucun intérimaire sur le Banc ! </EmptyStateTitle>
    <EmptyStateImage :src="importAsset('images/notioly/gestures.png')" />
  </EmptyState>
</template>

<script setup lang="ts">
import type { SubstituteBenchTableRow } from "@asap/shared/src/types/substitute-bench";

defineProps<{ contracts: SubstituteBenchTableRow[]; isFirstLoad?: boolean }>();
defineEmits<{ onSave: [SubstituteBenchTableRow] }>();

const contractOpened = ref<SubstituteBenchTableRow>();
</script>
