<template>
  <FilterButton @click="showSubstituteBenchVisibilityFilterModal = true"
    >Visible dans

    <template v-if="selectedNumber">
      - de
      <Badge>{{ selectedNumber }}</Badge>
      jours
      <FilterClear @click.stop="emit('onClear')" />
    </template>
  </FilterButton>

  <LazySubstituteBenchVisibilityFilterInput
    v-if="showSubstituteBenchVisibilityFilterModal"
    v-model="selectedNumber"
    @on-close="showSubstituteBenchVisibilityFilterModal = false"
  />
</template>

<script setup lang="ts">
type Emits = {
  onClear: [];
};

const emit = defineEmits<Emits>();

const showSubstituteBenchVisibilityFilterModal = ref(false);
const selectedNumber = defineModel<number>();
</script>
