<template>
  <PageContainer>
    <Title>🛋️ Banc de touche</Title>
    <PageHeader :icon="mapIcon.armchair" :title="`Banc de touche (${totalCount})`">
      <HeaderActions>
        <Button @click="showAddTalentOnBench = true">
          <ButtonIcon :icon="mapIcon.create" />
          Ajouter un talent
        </Button>
      </HeaderActions>

      <Dialog v-if="showAddTalentOnBench">
        <DialogContent>
          <DialogCloseButton @click="showAddTalentOnBench = false" />

          <DialogBody>
            <DialogInfo>
              <DialogIcon :icon="mapIcon.armchair" />
              <DialogTitle>Ajouter un talent sur le banc</DialogTitle>
            </DialogInfo>
            <FormField :icon="mapIcon.talent" label="Talent *" is-horizontal>
              <TalentResultItem
                v-if="talent"
                is-clickable
                is-removable
                :talent="talent"
                @on-remove="talent = undefined"
              />

              <DropdownButton v-else placeholder="Sélectionner un talent" @click="showTalentSearchModal = true" />

              <LazyTalentSearchModal
                v-if="showTalentSearchModal"
                @on-close="showTalentSearchModal = false"
                @on-select="
                  (value) => {
                    talent = value;
                    showTalentSearchModal = false;
                  }
                "
              />
            </FormField>
          </DialogBody>

          <DialogFooter>
            <Button @click="addTalentToBench">Ajouter</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </PageHeader>

    <FilterRow
      style="
        justify-content: space-between;
        border: unset;
        height: unset;
        padding-inline: unset;
        padding-bottom: 8px;
        padding-top: 8px;
      "
    >
      <FilterItems>
        <FilterSearch v-model="search" placeholder="Rechercher un talent" />

        <BusinessUnitFilterButton v-if="canSwitchBusinessUnit" v-model="businessUnits" />
        <TeamMemberFilterButton v-model="teamMembers" :can-switch-workspace-type="canSwitchWorkspace" />
        <PushMarketingFilterButton v-model="pushMarketingAction" @on-clear="pushMarketingAction = undefined" />
        <SubstituteBenchManoeuverToggleBar v-model="isManoeuverProfilesFilter" />
        <SubstituteBenchVisibilityFilterButton
          v-if="isSupportRole"
          v-model="daysBeforeUnlocked"
          @on-clear="daysBeforeUnlocked = undefined"
        />
      </FilterItems>
    </FilterRow>

    <PageBody>
      <PageBodyWrapper>
        <PageBodyBox>
          <div ref="listContainer" style="height: 100vh">
            <SubstituteBenchTable
              :is-first-load="isFirstLoad"
              :contracts="contracts.filter((c) => c.talent_is_benched === true)"
              @on-save="(contract) => (contracts = updateCollection(contracts, contract))"
            />
            <div v-if="!loading && !isFirstLoad" ref="listBottom" style="height: 1px; margin-top: -400px"></div>
            <Button
              v-if="showScrollToTop"
              variant="outline"
              size="small"
              class="scroll-to-top-button"
              @click="scrollToTop"
            >
              <ButtonIcon icon="lucide:arrow-up" />
            </Button>
          </div>
        </PageBodyBox>
      </PageBodyWrapper>
    </PageBody>
  </PageContainer>
</template>

<script setup lang="ts">
import type { Pagination } from "@asap/shared/src/types/pagination";
import type { SubstituteBenchTableQueryParams } from "@asap/shared/src/types/substitute-bench";
import type { BusinessUnit, Talent, TeamMember } from "@asap/shared/src/utils/supabase.types";
import { useBusinessUnit } from "~/composables/useBusinessUnit";
import { useToast } from "~/composables/useToast";
import { useWorkspaceMe } from "~/composables/useWorkspace";

const supabase = useSupabase();
const { isIndependent, businessUnit, isSupportRole } = useUserMe();
const { canSwitchBusinessUnit } = useBusinessUnit();
const { canSwitchWorkspace } = useWorkspaceMe();
const { getSubstituteBenchRows, totalCount } = useSubstituteBench();

const talent = ref<Talent>();

const businessUnits = ref<BusinessUnit[]>([]);
const teamMembers = ref<TeamMember[]>([]);
const pushMarketingAction = ref<string>();
const daysBeforeUnlocked = ref<number>();
const isManoeuverProfilesFilter = ref<boolean>(false);
const search = ref<string>("");

const showAddTalentOnBench = ref<boolean>(false);
const showTalentSearchModal = ref<boolean>(false);

const { addToastError } = useToast();

const listContainer = ref<HTMLElement | null>(null);
const listBottom = ref<HTMLElement | null>(null);

const substituteBenchFilters = computed(() => {
  const filters: SubstituteBenchTableQueryParams = {};
  filters.is_manoeuver_filter = isManoeuverProfilesFilter.value;
  if (pushMarketingAction.value === "push") filters.is_marketing_action = true;
  if (pushMarketingAction.value === "no push") filters.is_marketing_action = false;
  if (daysBeforeUnlocked.value) filters.days_before_unlock = daysBeforeUnlocked.value;
  if (teamMembers.value.length > 0) filters.consultants_ids = teamMembers.value.map((t) => t.id);
  if (!isSupportRole.value) filters.is_filtered_on_own_profiles = true;
  if (search.value) filters.search = search.value;
  if (businessUnits.value.length > 0) filters.business_unit_ids = businessUnits.value.map((b) => b.id);
  return filters;
});

const query = async (pagination?: Pagination, signal?: AbortSignal) => {
  try {
    return await getSubstituteBenchRows(substituteBenchFilters.value, pagination?.cursor, signal);
  } catch (error) {
    addToastError({ title: "Erreur lors de la récupération des profils" }, error);
  } finally {
    loading.value = false;
  }
};

const {
  records: contracts,
  loading,
  refresh,
  scrollToTop,
  showScrollToTop,
  isFirstLoad,
} = useKeysetPagination(listContainer, listBottom, query);

onMounted(() => {
  if (!canSwitchBusinessUnit.value && !isIndependent.value && businessUnit.value)
    businessUnits.value = [businessUnit.value];
});

const addTalentToBench = async function () {
  if (!talent.value) return;

  try {
    await supabase.from("talent").update({ is_benched: true }).eq("id", talent.value.id);

    await query();
    showAddTalentOnBench.value = false;
  } catch (error) {
    addToastError({ title: "Erreur lors de l'ajout du talent sur le banc" }, error);
  }
};

const debouncedRefresh = useDebounceFn(async () => {
  await refresh();
}, 800);

// Refresh when filters change. Deep watch to avoid reactivity issues (filters are a reactive object)
// Without { deep: true }, the watch would only trigger when you completely reassign the arrays like:
watch([substituteBenchFilters], refresh, { deep: true });
watch([search], debouncedRefresh);
</script>
<style scoped lang="scss">
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
</style>
