<template>
  <ToggleBar>
    <ToggleBarButton
      size="small"
      :variant="isManoeuverProfilesFilter ? 'ghost' : 'outline'"
      @click="isManoeuverProfilesFilter = false"
    >
      Hors manoeuvre
    </ToggleBarButton>
    <ToggleBarButton
      size="small"
      :variant="isManoeuverProfilesFilter ? 'outline' : 'ghost'"
      @click="isManoeuverProfilesFilter = true"
    >
      Manoeuvre
    </ToggleBarButton>
  </ToggleBar>
</template>
<script setup lang="ts">
const isManoeuverProfilesFilter = defineModel<boolean>();
</script>
