<template>
  <FilterButton @click="showPushMarketingActionSelectModal = true">
    Push Marketing
    <template v-if="selectedOption !== undefined">
      <Badge v-if="selectedOption === 'push'" size="sm">✅</Badge>
      <Badge v-if="selectedOption === 'no push'" size="sm">❌</Badge>
      <FilterClear @click.stop="emit('onClear')" />
    </template>
  </FilterButton>

  <ComboboxModal
    v-if="showPushMarketingActionSelectModal"
    v-model="selectedOptionAsArray"
    :options="options"
    :is-multiple="false"
    is-selected-option-removable
    :label="(option) => translateOption(option)"
    @on-close="showPushMarketingActionSelectModal = false"
  />
</template>
<script setup lang="ts">
type Emits = {
  onClear: [];
};

const emit = defineEmits<Emits>();

const selectedOption = defineModel<string>();
const selectedOptionAsArray = useItemAsArray(selectedOption);

// use of string instead of boolean because of wrong behavior of the combobox
// (both options were ticked when selectedOption was true, none was ticked when selectedOption was false)
const options = ["push", "no push"];

const translateOption = (option: string) => (option === "push" ? "Push Marketing ✅" : "Pas de push Marketing ❌");

const showPushMarketingActionSelectModal = ref(false);
</script>
