<template>
  <Row
    v-slot="{ isHovered }"
    :is-header="isHeader"
    style="
      grid-template-columns:
        minmax(20px, 0.15fr)
        minmax(20px, 0.2fr)
        minmax(180px, 1.2fr)
        minmax(130px, 1fr)
        minmax(180px, 1.2fr)
        minmax(130px, 1fr)
        minmax(130px, 1fr)
        repeat(3, minmax(100px, 0.4fr))
        minmax(40px, 0.2fr);
    "
  >
    <CellHeader v-if="isHeader"> </CellHeader>
    <CellSkeleton v-else-if="isSkeleton"></CellSkeleton>
    <Cell v-else-if="contract" has-padding-inline>
      <Tooltip v-if="contract.is_locked" :content="lockedText">
        <Icon :icon="mapIcon.lock" />
      </Tooltip>
    </Cell>

    <CellHeader v-if="isHeader"> </CellHeader>
    <CellSkeleton v-else-if="isSkeleton"></CellSkeleton>
    <Cell v-else-if="contract" has-padding-inline style="font-size: 8px">
      <Tooltip v-if="contract.talent_availability_status === 'available_now'" :content="availabilityText">
        <span v-if="contract.talent_availability_status === 'available_now'">🟢</span>
      </Tooltip>
      <Tooltip v-if="contract.talent_availability_status === 'available_soon'" :content="availabilityText">
        <span v-if="contract.talent_availability_status === 'available_soon'">🟠</span>
      </Tooltip>
      <Tooltip v-if="contract.talent_availability_status === 'not_available'" :content="availabilityText">
        <span v-if="contract.talent_availability_status === 'not_available'">🔴</span>
      </Tooltip>
    </Cell>

    <CellHeader v-if="isHeader"> Talent </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="contract"> {{ contract.talent_first_name }} {{ contract.talent_last_name }} </CellText>

    <CellHeader v-if="isHeader"> Consultant </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <Cell v-else-if="contract" has-padding-inline>
      <template v-if="contract.owner_first_name && contract.owner_last_name">
        <TextEllipsis> {{ contract.owner_first_name }} {{ contract.owner_last_name }} </TextEllipsis>
      </template>
      <template v-else> - </template>
    </Cell>

    <CellHeader v-if="isHeader"> Dernier poste </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="contract?.job_name">
      <Tooltip :content="contract.job_name">
        <TextEllipsis>{{ contract.job_name }}</TextEllipsis>
      </Tooltip>
    </CellText>

    <CellHeader v-if="isHeader"> Motif </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="contract">
      <Tooltip
        :content="contract.definitive_end_type ? translateDefinitiveEndType.fr[contract.definitive_end_type] : '-'"
      >
        <TextEllipsis>{{
          contract.definitive_end_type ? translateDefinitiveEndType.fr[contract.definitive_end_type] : "-"
        }}</TextEllipsis>
      </Tooltip>
    </CellText>

    <CellHeader v-if="isHeader"> Localisation </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="contract">
      {{ contract.talent_address_city }}
      {{ contract.talent_address_postal_code ? `/ ${contract.talent_address_postal_code}` : "" }}
    </CellText>

    <CellHeader v-if="isHeader" center> Jour sans contrat </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="contract" center>
      <Tooltip :content="daysSinceContractEndTooltip">
        <TextEllipsis>{{
          contract.days_since_contract_end
            ? contract.days_since_contract_end < 0
              ? `J${contract.days_since_contract_end}`
              : `J+${contract.days_since_contract_end}`
            : 0
        }}</TextEllipsis>
      </Tooltip>
    </CellText>

    <CellHeader v-if="isHeader" center> Candidatures </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="contract" center>
      {{ contract.ongoing_applications_count ? contract.ongoing_applications_count : 0 }}
    </CellText>

    <CellHeader v-if="isHeader" center> Marketing Push </CellHeader>
    <CellSkeleton v-else-if="isSkeleton" />
    <CellText v-else-if="contract" center>
      {{ contract.is_marketing_action ? "✅" : "❌" }}
    </CellText>

    <CellOptions :is-header="isHeader" :is-active="isHovered" @click="showSubstituteBenchCommandModal = true" />

    <LazySubstituteBenchCommandModal
      v-if="contract && showSubstituteBenchCommandModal"
      :contract="contract"
      @on-change="
        $emit('onChange', contract);
        showSubstituteBenchCommandModal = false;
      "
      @on-close="showSubstituteBenchCommandModal = false"
    />
  </Row>
</template>

<script setup lang="ts">
import { translateDefinitiveEndType } from "@asap/shared/src/utils/translate";
import { dateFormat } from "@asap/shared/src/utils/date";
import type { SubstituteBenchTableRow } from "@asap/shared/src/types/substitute-bench";

const props = defineProps<{ isHeader?: boolean; isSkeleton?: boolean; contract?: SubstituteBenchTableRow }>();
defineEmits<{ onChange: [SubstituteBenchTableRow] }>();

const daysSinceContractEndTooltip = computed(() => {
  if (!props.contract?.end_date) return "";
  return `Fin du dernier contrat le ${dateFormat(props.contract?.end_date, {
    options: { dateStyle: "medium" },
  })}`;
});

const availabilityText = computed(() => {
  if (props.contract?.talent_availability_status !== "available_now") return "";
  let text = `Disponible maintenant`;

  if (props.contract?.talent_availability_status_updated_at) {
    text += `- Mis à jour le ${dateFormat(props.contract.talent_availability_status_updated_at, {
      options: { dateStyle: "medium" },
    })}`;
  }
  return text;
});

const lockedText = computed(() => {
  let text = "Visible uniquement par vous";
  // Need to check if days_before_unlock is null because can be 0 and be considered nullish
  if (props.contract?.days_before_unlock === null || !props.contract) return text;

  if (props.contract?.days_before_unlock === 0) return (text += " jusqu'à demain");
  return (text += ` pendant encore ${props.contract.days_before_unlock} jours`);
});

const showSubstituteBenchCommandModal = ref(false);
</script>
