import type { CursorPagination } from "@asap/shared/src/types/pagination";
import type { SubstituteBenchTableQueryParams } from "@asap/shared/src/types/substitute-bench";

export const useSubstituteBench = () => {
  const supabase = useSupabase();
  const { invoke } = useApi();
  const totalCount = ref(0);

  onMounted(async () => {
    const response = await invoke({
      path: "substitute-bench/total-count",
      method: "GET",
    });

    if (!response.success) throw new Error(response.error.message);

    totalCount.value = response.data;
  });

  const removeFromBench = async (talentId: string) => {
    const { data, error } = await supabase.from("talent").update({ is_benched: false }).eq("id", talentId);

    if (error) throw new Error(error.message);

    return data;
  };

  const getSubstituteBenchRows = async (
    filters: SubstituteBenchTableQueryParams,
    cursor?: CursorPagination,
    signal?: AbortSignal
  ) => {
    const response = await invoke({
      path: "substitute-bench/view",
      method: "GET",
      query: { ...filters, ...cursor },
      signal,
    });

    if (!response.success) {
      if (isAbortError(response.error)) return [];
      throw new Error(response.error.message);
    }

    return response.data;
  };

  return { removeFromBench, getSubstituteBenchRows, totalCount };
};
